import { defaults } from './index.js';
import { gsapConfig } from './index.js';
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "./DrawSVGPlugin";

window.addEventListener("DOMContentLoaded", () => {
 gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);
 animateIcons();
 gsapConfig();
});

function animateIcons() {
 const paths = document.querySelectorAll(".st-path");
 paths.forEach(path => {
  let tl = gsap.timeline({
   scrollTrigger: {
    trigger: path,
    start: "top bottom",
   }
  });
  tl.fromTo(path, { drawSVG: "100% 100%" }, { drawSVG: "0% 100%", duration: defaults.duration * 1.5 });
 });
 return;
}
